.vmware-logo-dimensions {
    width: 140px;
    height: 22px;
}

.ui.menu .item.vmware-logo-item {
    padding-top: 0;
    padding-right: 0px;
}

.dashboard-title {
    text-align: center;
    margin-top: 0;
    letter-spacing: 2pt;
    color: #777;
}

.portal-menu.container {
    position: fixed;
    z-index: 101;
    margin: 0;
    width: 100%;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
}

.portal-menu > .menu:first-child {
    margin-bottom: 0 !important;
}

.portal-menu > .menu:last-child {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.ui.menu > .item.large-shield {
    padding: 0 16px;
}

.ui.menu > .item.large-shield > img {
    width: 3.0em !important;
}

.large-shield-dimensions {
    padding: 0 0px;
    width: 2.8em;
}