.footer-padding::after {
    content: "";
    display: block;
}

.footer {
    position: absolute;
    bottom: 0;
}

.footer p {
    margin: 0;
}
